/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import PageProxy from "@/proxies/PageProxy";
import ReservationProxy from "@/proxies/ReservationProxy";

export const list = ({}, { query, onSuccess, onError }) => {
  new ReservationProxy(query).all().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const page = ({}, { reservationId, onSuccess, onError }) => {
  new PageProxy().reservation(reservationId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const pageItem = ({}, { reservationId, onSuccess, onError }) => {
  new PageProxy().reservationItem(reservationId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const cancel = ({}, { reservationId, onSuccess, onError }) => {
  new ReservationProxy().cancel(reservationId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const sendVoucher = (
  {},
  { email, reservationId, onSuccess, onError }
) => {
  new ReservationProxy().sendVoucher(email, reservationId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const update = ({}, { params, reservationId, onSuccess, onError }) => {
  new ReservationProxy().updateItem(reservationId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const viewComments = ({}, { reservationId, onSuccess, onError }) => {
  new ReservationProxy().viewComments(reservationId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const addComment = (
  {},
  { params, reservationId, onSuccess, onError }
) => {
  new ReservationProxy().addComment(reservationId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export default {
  list,
  page,
  pageItem,
  cancel,
  sendVoucher,
  update,
  addComment,
  viewComments,
};
