import Proxy from "./Proxy";

class ReservationProxy extends Proxy {
  /**
   * The constructor for the LocationProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("reservations", parameters);
  }

  /**
   *
   * @param params
   * @returns {Promise}
   */
  book(params) {
    return this.submit("post", `${this.endpoint}`, params);
  }

  /**
   *
   * @param reservationId
   * @returns {Promise}
   */
  fees(reservationId) {
    return this.submit("get", `${this.endpoint}/${reservationId}/fees`);
  }

  /**
   *
   * @param reservationId
   * @returns {Promise}
   */
  cancel(reservationId) {
    return this.submit("post", `${this.endpoint}/${reservationId}/cancel`);
  }

  /**
   *
   * @param email
   * @param reservationId
   * @returns {Promise}
   */
  sendVoucher(email, reservationId) {
    return this.submit(
      "post",
      `${this.endpoint}/${reservationId}/vouchers/send`,
      {
        email,
      }
    );
  }

  /**
   * @returns {Promise}
   */
  updateItem(reservationId, params) {
    return this.submit("post", `${this.endpoint}/${reservationId}`, params);
  }

  /**
   * @returns {Promise}
   */
  viewComments(reservationId) {
    return this.submit("get", `${this.endpoint}/${reservationId}/comments`);
  }

  /**
   * @returns {Promise}
   */
  addComment(reservationId, params) {
    return this.submit(
      "post",
      `${this.endpoint}/${reservationId}/comments`,
      params
    );
  }
}

export default ReservationProxy;
