import OfferProxy from "@/proxies/OfferProxy";
import * as types from "./mutation-types";

export const get = ({}, { offerId, onSuccess, onError }) => {
  new OfferProxy().find(offerId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const edit = ({}, { offer, offerId, onSuccess, onError }) => {
  new OfferProxy().update(offerId, offer).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const list = ({}, { query, onSuccess, onError }) => {
  new OfferProxy(query).all().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const current = ({ commit }) => {
  new OfferProxy().current().then(
    (response) => {
      // Success Callback
      commit(types.SET_ITEMS, response.offer.items);
      commit(types.SET_COUNT, response.offer.count);
    },
    () => {
      // Error Callback
      commit(types.SET_ITEMS, {});
      commit(types.SET_COUNT, 0);
    }
  );
};

export const currentCreate = ({ commit }, { offer, onSuccess, onError }) => {
  new OfferProxy().createOffer(offer).then(
    (response) => {
      // Mutation
      commit(types.SET_ITEMS, {});
      commit(types.SET_COUNT, 0);
      // Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const addOffer = ({ commit }, { params, onSuccess, onError }) => {
  new OfferProxy().addOffer(params).then(
    (response) => {
      commit(types.SET_ITEMS, response.offer.items);
      commit(types.SET_COUNT, response.offer.count);
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const removeOffer = (
  { commit },
  { isNew, params, onSuccess, onError }
) => {
  new OfferProxy().removeOffer(params).then(
    (response) => {
      if (isNew) {
        commit(types.SET_ITEMS, response.offer.items);
        commit(types.SET_COUNT, response.offer.count);
      }
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const deleteOffer = ({}, { offerId, onSuccess, onError }) => {
  new OfferProxy().destroy(offerId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export default {
  get,
  edit,
  list,
  current,
  currentCreate,
  addOffer,
  removeOffer,
  deleteOffer,
};
