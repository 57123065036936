/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */
import Vue from "vue";
import Vuex from "vuex";
import VueCarousel from "vue-carousel";
import VeeValidate from "vee-validate";
import VueLazyLoad from "vue-lazyload";
import VueScrollTo from "vue-scrollto";
import ElementUI from "element-ui";
import createLogger from "vuex/dist/logger";
import * as VueGoogleMaps from "vue2-google-maps";
import { i18n } from "@/plugins/vue-i18n";
import dictionary from "../utils/validator/dictionary";

// Modules
import settings from "./modules/settings";
import user from "./modules/user";
import auth from "./modules/auth";
import offer from "./modules/offer";
import admin from "./modules/admin";
import hotel from "./modules/hotel";
import hotels from "./modules/hotels";
import agency from "./modules/agency";
import invoices from "./modules/invoices";
import payments from "./modules/payments";
import transfers from "./modules/transfers";
import activities from "./modules/activities";
import reservations from "./modules/reservations";
import messages from "./modules/messages";
import notifications from "./modules/notifications";

Vue.use(Vuex);
Vue.use(VueCarousel);
Vue.use(VueScrollTo);
Vue.use(VeeValidate, {
  fieldsBagName: "veeFields",
});
Vue.use(VueLazyLoad);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDUUTOwgyOaKkg60oj5Y2nuaAChNN_oU10",
  },
});
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});

VeeValidate.Validator.extend("daterange", {
  getMessage: (field) => `The ${field} field is required.`,
  validate: (value) => value[0] !== null || value[1] !== null,
});

VeeValidate.Validator.localize(dictionary);

Vue.prototype.$formatPrice = (price, currency) => {
  if (currency === "BGN") {
    return `${(parseFloat(price) * 1.956).toFixed(2)} BGN`;
  }
  if (currency === "USD") {
    return `${(parseFloat(price) * 1.074).toFixed(2)} USD`;
  }
  return `${parseFloat(price).toFixed(2)} EUR`;
};

Vue.prototype.$getIsoDate = (date) => {
  const dateTimeParts = date.split("T");
  return dateTimeParts[0];
};

Vue.prototype.$formatDate = (date) => {
  const dateTimeParts = date.split(" ");
  const dateParts = dateTimeParts[0].split("-");
  const dateString = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
  if (dateTimeParts[1]) {
    return `${dateString} ${dateTimeParts[1]}`;
  }
  return dateString;
};

Vue.prototype.$formatTime = (time) => {
  const timeParts = time.split(":");
  return `${timeParts[0]}:${timeParts[1]}`;
};

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  /**
   * Assign the modules to the store.
   */
  modules: {
    settings,
    user,
    auth,
    offer,
    admin,
    hotel,
    hotels,
    agency,
    invoices,
    payments,
    transfers,
    activities,
    reservations,
    messages,
    notifications,
  },

  /**
   * If strict mode should be enabled.
   */
  strict: debug,

  /**
   * Plugins used in the store.
   */
  plugins: debug ? [createLogger()] : [],
});
