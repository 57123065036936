import store from "@/store";
import UserProxy from "@/proxies/UserProxy";
import * as types from "./mutation-types";

export const get = ({}, { userId, onSuccess, onError }) => {
  new UserProxy().get(userId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const list = ({}, { query, onSuccess, onError }) => {
  new UserProxy(query).all().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const create = ({}, { params, onSuccess, onError }) => {
  new UserProxy().create(params).then(
    (response) => {
      // Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const update = ({}, { userId, params, onSuccess, onError }) => {
  new UserProxy().update(userId, params).then(
    (response) => {
      // Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const current = ({ commit }, { onError }) => {
  new UserProxy().current().then(
    (response) => {
      // Mutation
      commit(types.CURRENT, response.user);
    },
    (error) => {
      // Mutation
      commit(types.RESET);
      // Dispatch logout
      store.dispatch("auth/logout");
      // Error Callback
      onError(error);
    }
  );
};

export const currentUpdate = ({ commit }, { agent, callback }) => {
  new UserProxy().currentUpdate(agent).then((response) => {
    // Mutation
    commit(types.UPDATE, response.user);
    // Callback
    callback(response);
  });
};

export default {
  get,
  list,
  create,
  update,
  current,
  currentUpdate,
};
